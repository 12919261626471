import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import DirectionReveal from "direction-reveal";
import PortfolioContent from "./modal/PortfolioContent";

const PortfolioLight = () => {
  
  const portfolioData = [
    {
      uniqueName:"jobr",
      imageType:"slider", // 'slider' or 'image
      projectTitle:"JOBR",
      projectType:"Web and Mobile Application",
      previewTitle:"www.jobr.com",
      previewUrl:"https://jobr.com",
      technologies:"React JS, Node JS, MySQL, Socket",
      image:"jobr.JPG",
      images:['jobr.JPG', 'jobr_slider_1.JPG', 'jobr_slider_2.JPG', 'jobr_slider_3.JPG'],  // Enable in case of slider
      modalImage:"jobr.JPG",
      description:
        <>
          <b>JOBR</b> is an E-Commerce platform where users can buy or sell products online through various applications such as B2C, POS, and B2B. In this project, I worked as a team lead, responsible for handling all backend tasks.
        </>
    },
    {
      uniqueName:"qrp",
      imageType:"slider", // 'slider' or 'image
      projectTitle:"QRP",
      projectType:"Web Application",
      previewTitle:"N/A",
      previewUrl:"",
      technologies:"React JS, Node JS, MongoDB",
      image:"qrp.png",
      images:['qrp_slider_1.png', 'qrp_slider_2.png', 'qrp_slider_3.png', 'qrp_slider_4.png'],  // Enable in case of slider
      modalImage:"qrp.png",
      description:
        <>
          The Questionnaire Application allows users to create and manage question sets efficiently. Admins can organize questions into structured sets, enabling easy customization. Admin can also export the question sets in the PDF format.
        </>
    },
    {
      uniqueName:"unity_booth",
      imageType:"slider", // 'slider' or 'image
      projectTitle:"Unity Booth",
      projectType:"Web Application",
      previewTitle:"www.mystictan.app",
      previewUrl:"https://www.mystictan.app/salon-portal/login",
      technologies:"React JS, Node JS, MySQL, Socket, Stripe",
      image:"unity_booth_1.PNG",
      images:['unity_booth_1.PNG', 'unity_booth_2.PNG', 'unity_booth_3.PNG'],  // Enable in case of slider
      modalImage:"unity_booth_1.PNG",
      description:
        <>
          <b>Unity Booth (Mystictan)</b> is a platform where users can book beauty treatment sessions. In this project, I was responsible for the UI design of both the user portal and the admin portal. For the UI development, I utilized React JS.
        </>
    },
    {
      uniqueName:"shepherd_cares",
      imageType:"image", // 'slider' or 'image
      projectTitle:"ShepherdCares",
      projectType:"Web & Mobile Application",
      previewTitle:"www.shepherdcares.com",
      previewUrl:"https://shepherdcares.com",
      technologies:"React JS, Node JS, MySQL, Stripe",
      image:"shepherdcares.png",
      modalImage:"shepherdcares.png",
      description:
        <>
          <b>ShepherdCare</b> empowers family caregivers to prioritize their own health while caring for their loved ones. In this project, I took charge of designing the UI for both the web and admin portals, as well as developing the APIs for mobile applications. React JS was the primary framework utilized for the UI development in this project.
        </>
    },
    {
      uniqueName:"timely_hire",
      imageType:"image", // 'slider' or 'image
      projectTitle:"Timely Hire",
      projectType:"Web & Mobile Application",
      previewTitle:"www.timelyhire.com",
      previewUrl:"https://timelyhire.com",
      technologies:"React JS, Java",
      image:"timely_hire.png",
      modalImage:"timely_hire.png",
      description:
        <>
          <b>TimlyHire</b> is a platform where job seekers can find suitable employment opportunities, and employers can post their hiring requirements. In this project, my responsibility was solely focused on designing the UI for the admin portal.
        </>
    },
    {
      uniqueName:"times_identity",
      imageType:"image", // 'slider' or 'image
      projectTitle:"Times",
      projectType:"Web Application",
      previewTitle:"N/A",
      previewUrl:"",
      technologies:"React JS, Node JS, Mongo DB, Blockchain, Stripe, Socket.io",
      // images:['TIV_Slider_1.png', 'TIV_Slider_2.png', 'TIV_Slider_3.png'],  // Enable in case of slider
      image:"TIV.png",
      modalImage:"TIV.png",
      description:
        <>
          Times - Identity Verification is a project of <b>AtCash</b> which is used to verify users' identities using methods such as passport or driving license verification, Knowledge-Based Authentication (KBA), and liveness detection. In this project, my responsibility was focused on front-end technologies.
        </>
    },
    {
      uniqueName:"times_atnotarize",
      imageType:"image", // 'slider' or 'image
      projectTitle:"Atnotarize",
      projectType:"Web Application",
      previewTitle:"N/A",
      previewUrl:"",
      technologies:"React JS, Node JS, Mongo DB, Agora, Socket.io, Stripe, Blockchain",
      image:"TN.png",
      modalImage:"TN.png",
      description:
        <>
          Atnotarize is a product of
          {" "}<b>AtCash</b>{" "}
          which is used for notarizing and e-signing documents with live calls. In this product, I was responsible for both the front-end development and some backend APIs.
        </>
    },
    {
      uniqueName:"smarttask",
      imageType:"image", // 'slider' or 'image
      projectTitle:"SmartTask",
      projectType:"Mobile Application",
      previewTitle:"N/A",
      previewUrl:"",
      technologies:"React JS, Node JS, Mongo DB, Socket, Stripe, Java (For Mobile Application)",
      image:"smarttask.png",
      modalImage:"smarttask.png",
      description:
        <>
          <b>SmartTask</b>{" "}
          is a mobile application designed to offer various on-demand services such as painting, window cleaning, and moving assistance. In this project, my responsibilities encompassed the development of APIs and the creation of the admin dashboard.
        </>
    },
    {
      uniqueName:"succession_plan",
      imageType:"slider", // 'slider' or 'image
      projectTitle:"Succession Plan",
      projectType:"Web Application",
      previewTitle:"N/A",
      previewUrl:"",
      technologies:"Laravel, MySql",
      image:"succession_plan.png",
      images:['succession_plan_slider_1.png', 'succession_plan_slider_2.png', 'succession_plan_slider_3.png'],  // Enable in case of slider
      modalImage:"qrp.png",
      description:
        <>
          <b>Succession Plan</b>{" "}
          is a smart profile-matching platform designed to connect job seekers with the most relevant opportunities. By analyzing candidates’ skills, experience, and job preferences, our system ensures efficient and accurate matching with suitable job listings.
        </>
    },
    {
      uniqueName:"life_celebration",
      imageType:"image", // 'slider' or 'image
      projectTitle:"Life Celebration",
      projectType:"Web Application",
      previewTitle:"www.lifecelebrationinc.com",
      previewUrl:"https://www.lifecelebrationinc.com/",
      technologies:"Vue JS, J-Query, PHP (Laravel), MySql, Bootstrap, SASS",
      image:"life_celebration.png",
      modalImage:"life_celebration_modal.png",
      description:
        <>
          <a href="https://shop.lifecelebrationinc.com/account/login" target="_blank" rel="noreferrer">Life Celebration</a>{" "}
          is an order processing system for memorial products tailored for funeral homes in the USA. In this project, my responsibility extended to both the backend and frontend development of the admin dashboard.
        </>
    },
    {
      uniqueName:"dentihire",
      imageType:"image", // 'slider' or 'image
      projectTitle:"Dentihire",
      projectType:"Web Application",
      previewTitle:"N/A",
      previewUrl:"",
      technologies:"PHP (Laravel), J-Query, MySql, SASS, Stripe, WebRTC",
      image:"dentihire.png",
      modalImage:"dentihire.png",
      description:
        <>
          <b>Dentihire</b> is a web portal facilitating communication between doctors and staff for job searches through video conferencing. I contributed to this project as an assistant developer. Despite my supporting role, I successfully resolved a major issue within the platform. Furthermore, I actively engaged with the developer community on
          {" "}<a href="https://stackoverflow.com/questions/53626318/chrome-update-failed-to-execute-createobjecturl-on-url/54293923#54293923" target="_blank" rel="noreferrer">StackOverflow</a>,{" "}
          offering assistance to resolve similar issues.
        </>
    },
    // {
    //   uniqueName:"dm_crm",
    //   imageType:"image", // 'slider' or 'image
    //   projectTitle:"DM CRM",
    //   projectType:"Web Application",
    //   previewTitle:"N/A",
    //   previewUrl:"",
    //   technologies:"PHP (Codeigniter), MySql, J-Query, Bootstrap, CSS",
    //   image:"dm_crm.png",
    //   modalImage:"dm_crm.png",
    //   description:"It's an internal-use CRM (Customer Relationship Management) system. I joined the project as a senior software developer and led its successful delivery.",
    // },
    {
      uniqueName:"raise_your_iq",
      imageType:"image", // 'slider' or 'image
      projectTitle:"Raise Your IQ",
      projectType:"Web Application",
      previewTitle:"www.raiseyouriq.com",
      previewUrl:"https://raiseyouriq.com/",
      technologies:"PHP (Laravel), MySql, J-Query, Bootstrap, CSS, PayPal",
      image:"raise_your_iq.png",
      modalImage:"raise_your_iq.png",
      description:
      <>
        <b>Raise Your IQ</b>{" "}
        is a platform designed to improve general cognitive skills and provide support for various emotional challenges. It is also beneficial for enhancing educational aptitude.
      </>
    },
    {
      uniqueName:"360_journal",
      imageType:"image", // 'slider' or 'image
      projectTitle:"360° Journal",
      projectType:"Mobile Application",
      previewTitle:"www.360employeewellness.com",
      previewUrl:"https://www.360employeewellness.com",
      technologies:"PHP (Laravel), MySql, Java (For Mobile Application)",
      image:"360_journal.png",
      modalImage:"360_journal.png",
      description:
      <>
        <b>360° Journal</b>{" "}
        is a mobile application aimed at reducing stress and increasing energy levels. I developed the Restful APIs for this application using the Laravel framework in PHP.
      </>
    },
    // {
    //   uniqueName:"belwo",
    //   imageType:"image", // 'slider' or 'image
    //   projectTitle:"Belwo",
    //   projectType:"Website",
    //   previewTitle:"www.belwo.com",
    //   previewUrl:"https://www.belwo.com/",
    //   technologies:"Wordpress, MySql, CSS",
    //   image:"belwo.png",
    //   modalImage:"belwo.png",
    //   description:"It's a static website showcasing its services. I worked on it independently and successfully delivered the project.",
    // },
    // {
    //   uniqueName:"rashtriyatrading",
    //   imageType:"image", // 'slider' or 'image
    //   projectTitle:"Rashtriya Craft Exports",
    //   projectType:"Website",
    //   previewTitle:"www.rashtriyatrading.com",
    //   previewUrl:"https://www.rashtriyatrading.com/",
    //   technologies:"Wordpress, MySql, CSS",
    //   image:"rashtriyatrading.png",
    //   modalImage:"rashtriyatrading.png",
    //   description:"Rashtriya Craft Exports is a prominent trading company specializing in exporting various products including rice, grains, pulses, spices, and handicrafts. I developed a static website to showcase the company's wide range of products.",
    // },
    {
      uniqueName:"shop_demo",
      imageType:"slider", // 'slider' or 'image
      projectTitle:"E-Store",
      projectType:"Web Application",
      previewTitle:"www.shop.devrajput.in",
      previewUrl:"https://shop.devrajput.in/",
      technologies:"Laravel, MySql, Paytm Payment Gateway",
      image:"shop-demo-2.png",
      images:['shop-demo-2.png', 'shop_demo_slider_1.png', 'shop_demo_slider_2.png', 'shop_demo_slider_3.png'],  // Enable in case of slider
      modalImage:"shop-demo-2.png",
      description:"E-Store is a multi-vendor E-commerce platform developed using Laravel. Its primary objective is to connect users with local shops in their city, offering fresh products such as vegetables, fruits, and dairy foods. Users start by selecting their preferred shop and can then browse and purchase products available from that specific shop.",
    },
    // {
    //   uniqueName:"dinkeragri",
    //   imageType:"image", // 'slider' or 'image
    //   projectTitle:"Dinker Agriculture Producer Company Ltd.",
    //   projectType:"Website",
    //   previewTitle:"www.dinkeragri.com",
    //   previewUrl:"https://dinkeragri.com",
    //   technologies:"Wordpress, MySql, CSS",
    //   image:"dinkeragri.png",
    //   modalImage:"dinkeragri.png",
    //   description:"Dinker Agriculture Producer Company Ltd. (DAPCL) is a farmer-led producer company specializing in fruits and vegetables, with farmers as key stakeholders. I developed a static website for the company to showcase its products and services.",
    // },
    {
      uniqueName:"gk_quiz",
      imageType:"slider", // 'slider' or 'image
      projectTitle:"General Knowledge Quiz",
      projectType:"Web Application",
      previewTitle:"www.quiz.devrajput.in",
      previewUrl:"https://quiz.devrajput.in/",
      technologies:"Laravel, MySql",
      image:"gk_quiz_slider_2.PNG",
      images:['gk_quiz_slider_1.PNG', 'gk_quiz_slider_2.PNG', 'gk_quiz_slider_3.PNG'],  // Enable in case of slider
      modalImage:"gk_quiz_slider_2.PNG",
      description:"This is my demo project created using Laravel and MySQL. Building this project has enhanced my UI skills and logical understanding. It's a general knowledge questionnaire consisting of 20 questions on world general knowledge. Participants are given 5 minutes to complete the quiz. Upon submission, users receive a performance summary.",
    }
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState({});

  function toggleModal(portfolioUniqueName = "") {
    if(!isModalOpen){
      if(portfolioUniqueName){
        let selectedPortfolio = portfolioData.find(item => item.uniqueName === portfolioUniqueName);
        setSelectedPortfolio(selectedPortfolio);
        setIsModalOpen(!isModalOpen);
      }
    }
    else {
      setIsModalOpen(!isModalOpen);
    }
  }

  useEffect(() => {
    // Init with default setup
    DirectionReveal();

    // Init with all options at default setting
    DirectionReveal({
      selector: ".direction-reveal",
      itemSelector: ".direction-reveal__card",
      animationName: "slide",
      animationPostfixEnter: "enter",
      animationPostfixLeave: "leave",
      touchThreshold: 500,
    });
  }, []);

  return (
    <ul className="row grid">
      {console.log("render test")}
      {/* <!-- Portfolio Item Starts --> */}
      { portfolioData.map((item, index) => {
          return (
            <li className="direction-reveal" key={index}>
              <figure className="direction-reveal__card" onClick={() => {toggleModal(item.uniqueName)}}>
                <img src={"img/projects/"+item.image} alt="Portolio" />
                <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                  <span className="direction-reveal__title">{item.projectTitle}</span>
                </div>
              </figure>
            </li>
          );
        })
      }
      {/* <!-- Portfolio Item Ends --> */}

      {/* Start PortfolioContent */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="custom-modal dark green"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModal}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner portfolio">
            <PortfolioContent {...selectedPortfolio}/>
          </div>
        </div>
      </Modal>
      {/* End  PortfolioContent */}

      
    </ul>
  );
};

export default PortfolioLight;
